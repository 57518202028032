//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      images: [
        {
          name: '1',
        },
        {
          name: '2',
        },
        {
          name: '3',
        },
        {
          name: '4',
        },
        {
          name: '5',
        },
        {
          name: '6',
        },
        {
          name: '7',
        },
        {
          name: '8',
        },
        {
          name: '9',
        },
        {
          name: '10',
        },
        {
          name: '11',
        },
        {
          name: '12',
        },
        {
          name: '13',
        },
        {
          name: '14',
        },
        {
          name: '15',
        },
        {
          name: '16',
        },
        {
          name: '17',
        },
        {
          name: '19',
        },
        {
          name: '20',
        },
        {
          name: '21',
        },
        {
          name: '22',
        },
        {
          name: '23',
        },
        {
          name: '24',
        },
        {
          name: '25',
        },
        {
          name: '26',
        },
        {
          name: '27',
        },
        {
          name: '28',
        },
        {
          name: '29',
        },
        {
          name: '30',
        },
        {
          name: '31',
        },
        {
          name: '32',
        },
        {
          name: '33',
        },
        {
          name: '34',
        },
        {
          name: '35',
        },
        {
          name: '37',
        },
        {
          name: '38',
        },
        {
          name: '39',
        },
        {
          name: '40',
        },
        {
          name: '41',
        },
        {
          name: '42',
        },
        {
          name: '43',
        },
        {
          name: '44',
        },
        {
          name: '45',
        },
        {
          name: '46',
        },
        {
          name: '47',
        },
        {
          name: '48',
        },
        {
          name: '49',
        },
        {
          name: '50',
        },
        {
          name: '51',
        },
        {
          name: '52',
        },
        {
          name: '53',
        },
        {
          name: '54',
        },
        {
          name: '55',
        },
        {
          name: '56',
        },
        {
          name: '57',
        },
        {
          name: '58',
        },
        {
          name: '59',
        },
        {
          name: '60',
        },
        {
          name: '61',
        },
        {
          name: '62',
        },
        {
          name: '63',
        },
        {
          name: '64',
        },
        {
          name: '65',
        },
        {
          name: '66',
        },
        {
          name: '67',
        },
        {
          name: '68',
        },
        {
          name: '69',
        },
        {
          name: '70',
        },
        {
          name: '71',
        },
        {
          name: '72',
        },
        {
          name: '73',
        },
        {
          name: '74',
        },
        {
          name: '75',
        },
      ],
    }
  },
  computed: {
    data() {
      return this.images
    },
  },
  mounted() {
    const imgList = document.querySelector('.works__list')
    let isDown = false
    let startX
    let scrollLeft

    imgList.addEventListener('mousedown', (e) => {
      isDown = true
      imgList.classList.add('active')
      startX = e.pageX - imgList.offsetLeft
      scrollLeft = imgList.scrollLeft
    })
    imgList.addEventListener('mouseleave', () => {
      isDown = false
      imgList.classList.remove('active')
    })
    imgList.addEventListener('mouseup', () => {
      isDown = false
      imgList.classList.remove('active')
    })
    imgList.addEventListener('mousemove', (e) => {
      if (!isDown) return
      e.preventDefault()
      const x = e.pageX - imgList.offsetLeft
      const walk = (x - startX) * 3
      imgList.scrollLeft = scrollLeft - walk
    })
  },
}
